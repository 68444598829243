import * as React from 'react';

import { CoreProps, PK } from 'common/types';
import { MEDIA_TYPES, STATUSES_OPTIONS } from 'common/consts';
import {
  Input,
  Select,
  Title,
  MediaField,
  ContentGoalSelect,
  Textarea,
} from 'common/components';
import { TitleInput } from 'templates/details';
import {
  CoreFormLayout,
  FieldsGrid,
  GridItem,
  Section,
  SectionsWrapper,
} from 'templates/core';

import { useToast } from 'modules/toast';
import { useWorkoutCreate, useWorkoutUpdate } from '../queries';
import { toastMessages } from 'common/toastMessages';
import { fields, validationSchema, WorkoutFields } from '../consts';
import WorkoutTagsPicker from './WorkoutTagsPicker';

const useSave = (isTags: boolean, pk?: PK) => {
  const { toast } = useToast();
  const [created, setCreated] = React.useState(pk);
  const create = useWorkoutCreate();
  const update = useWorkoutUpdate();
  const isSaving = create.isLoading || update.isLoading;
  const error = create.error || update.error;

  const save = (values: WorkoutFields) => {
    if (values?.status[0].value === 'ACTIVE' && !isTags) {
      toast(toastMessages.ERROR.CHANGING_STATUS_WITHOUT_TAGS, 'error');
      return;
    }
    const payload = {
      title: values[fields.title.name],
      description: values[fields.description.name],
      media_file_pk: values[fields.media_file_pk.name][0].pk,
      content_goal_pks: values[fields.content_goals.name].map((option) => option.value),
    };
    if (created) {
      update.mutate({
        ...payload,
        pk: created,
        status: values[fields.status.name][0].value,
      });
    } else {
      create.mutate(payload, {
        onSuccess: ({ pk }) => {
          setCreated(pk);
        },
      });
    }
  };

  return { save, created, isSaving, error };
};

const WorkoutCore = ({
  pk,
  defaultValues,
  layout = 'overview',
}: CoreProps<WorkoutFields>) => {
  const [isTags, setIsTags] = React.useState(false);
  const { save, isSaving, error, created } = useSave(isTags, pk);

  return (
    <>
      <CoreFormLayout
        onSubmit={save}
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isSubmitting={isSaving}
        error={error}
      >
        {layout === 'overview' && (
          <Section>
            <TitleInput name={fields.title.name} />
          </Section>
        )}
        <Section>
          <FieldsGrid>
            <GridItem compact>
              <Select
                name={fields.status.name}
                options={STATUSES_OPTIONS}
                readOnly={!created}
              />
            </GridItem>
            <GridItem compact>
              <ContentGoalSelect
                isMulti
                name={fields.content_goals.name}
                label={fields.content_goals.label}
              />
            </GridItem>
            {layout === 'creation' && (
              <GridItem>
                <Input name={fields.title.name} label={fields.title.label} />
              </GridItem>
            )}
          </FieldsGrid>
        </Section>
        <Section>
          <Title marginBottom={3}>General</Title>
          <FieldsGrid>
            <GridItem>
              <Textarea name={fields.description.name} label={fields.description.label} />
            </GridItem>
          </FieldsGrid>
        </Section>
        <Section>
          <Title marginBottom={3}>Video options</Title>
          <Title size="small" marginBottom={1}>
            Connected video file
          </Title>
          <MediaField name={fields.media_file_pk.name} mediaType={MEDIA_TYPES.VIDEO} />
        </Section>
      </CoreFormLayout>
      <SectionsWrapper pk={created}>
        <WorkoutTagsPicker setIsTags={setIsTags} />
      </SectionsWrapper>
    </>
  );
};

export default WorkoutCore;
